import React from 'react'
import { fabric } from 'fabric';


class TileCanvas extends React.Component {
    constructor(props){
        super(props);
        this.state = {canvas: null,
                    fgColor: this.props.fgColor,
                    bgColor: this.props.bgColor,
                    tileSize: this.props.tileSize,
                    vSpace: this.props.vSpace,
                    hSpace: this.props.hSpace,
                    }
      }

  componentDidMount() {

    let canvas = new fabric.StaticCanvas("TileCanvas", {
        backgroundColor: this.props.bgColor,
    })
    canvas.on('object:scaled', this.onObjectScaled);

    fabric.Object.prototype.noScaleCache = false;

    let width = this.props.width
    let height = this.props.height
    canvas.setWidth(width);
    canvas.setHeight(height);
    document.getElementById("TileCanvas").fabric = canvas;

    let fgColor = this.props.fgColor;
    let tileSize = this.props.tileSize;
    let hSpace = this.props.hSpace;
    let vSpace = this.props.vSpace;
    let strokeColor = this.props.strokeColor;
    let strokeWidth = this.props.strokeWidth;
    let angle = this.props.angle;
    
    fabric.loadSVGFromURL(`/img/${this.props.directory}/${this.props.file}`, function(objects, options) {
        let masterShape = fabric.util.groupSVGElements(objects, options);

        let tileRatio = masterShape.height / masterShape.width;
        
        let tileHeight = tileSize * tileRatio;

        let hInterval = Number(tileSize) + Number(hSpace)
        let rowMax = Math.ceil(width / hInterval);
        let vInterval = Number(tileHeight) + Number(vSpace)
        let colMax = Math.ceil(height / vInterval);
        let tileMax = rowMax * colMax;
        let rowNum = 0;

        for (let i=0; i<tileMax; i++){
            if ( (i - rowNum * rowMax) >= rowMax){rowNum++}
            let xPos = (i - rowNum * rowMax) * hInterval;
            let yPos = rowNum * vInterval;
            let shape = fabric.util["object"].clone(masterShape);

            if (shape){

                shape.set({
                    left: xPos,
                    top: yPos,
                });
                if (Number(strokeWidth) === 0 || !strokeWidth){
                    shape.set({
                        stroke: false,
                    }); 
                } else {
                    shape.set({
                        strokeWidth: strokeWidth,
                        stroke: strokeColor,
                        strokeUniform: true
                    });
                }

                shape.scaleToWidth(tileSize);

                shape.set({
                    angle: angle,
                });

                if (!shape._objects) {
                    shape.set('fill', fgColor);
                } else {
                    for (let j = 0; j < shape._objects.length; j++) {
                        shape._objects[j].set({
                        fill: fgColor
                        });
                    }
                }
                canvas.add(shape)
            }
        }
        canvas.renderAll();
    })
    
    
    this.setState({created: true})
  }

  componentDidUpdate(prevProps) {
    let canvas = document.getElementById("TileCanvas").fabric;
    fabric.Object.prototype.noScaleCache = false;

    let width = this.props.width
    let height = this.props.height

    if (prevProps.width !== this.props.width || prevProps.height !== this.props.height){
        let width = this.props.width
        let height = this.props.height
        canvas.setWidth(width);
        canvas.setHeight(height);
    }
    
    let fgColor = this.props.fgColor;
    let bgColor = this.props.bgColor;
    let tileSize = this.props.tileSize;
    let strokeColor = this.props.strokeColor;
    let strokeWidth = this.props.strokeWidth;
    let angle = this.props.angle

    if (prevProps.bgColor !== this.props.bgColor){
        canvas.backgroundColor = bgColor;
    }
    if (prevProps.fgColor !== this.props.fgColor){
        let objects = canvas.getObjects()
        for (let obj of objects){
            if (!obj._objects) {
                obj.set('fill', fgColor);
            } else {
                for (let i = 0; i < obj._objects.length; i++) {
                    obj._objects[i].set({
                    fill: fgColor
                    });
                }
            }
        }
    }
    
    if (prevProps.tileSize !== this.props.tileSize
         || prevProps.hSpace !== this.props.hSpace
         || prevProps.vSpace !== this.props.vSpace
         || prevProps.file !== this.props.file
         || prevProps.angle !== this.props.angle 
         ||prevProps.width !== this.props.width || prevProps.height !== this.props.height){
        canvas.clear();
        canvas.backgroundColor = this.props.bgColor;
        let hSpace = this.props.hSpace;
        let vSpace = this.props.vSpace;
        
        fabric.loadSVGFromURL(`/img/${this.props.directory}/${this.props.file}`, function(objects, options) {
            let masterShape = fabric.util.groupSVGElements(objects, options);
            let tileRatio = masterShape.height / masterShape.width;
            let tileHeight = tileSize * tileRatio;
            let hInterval = Number(tileSize) + Number(hSpace)
            let rowMax = Math.ceil(width / hInterval);
            let vInterval = Number(tileHeight) + Number(vSpace)
            let colMax = Math.ceil(height / vInterval);
            let tileMax = rowMax * colMax;
            let rowNum = 0;
    
            for (let i=0; i<tileMax; i++){
                if ( (i - rowNum * rowMax) >= rowMax){rowNum++}
                let xPos = (i - rowNum * rowMax) * hInterval;
                let yPos = rowNum * vInterval;
                let shape = fabric.util["object"].clone(masterShape);

                if (shape){
    
                    shape.set({
                        left: xPos,
                        top: yPos,
                    });
                    if (Number(strokeWidth) === 0 || !strokeWidth){
                        shape.set({
                            stroke: false,
                        });
                    }else{
                        shape.set({
                            strokeWidth: strokeWidth,
                            stroke: strokeColor,
                            strokeUniform: true
                        });
                    }

                        
    
                    shape.scaleToWidth(tileSize);
    
                    shape.set({
                        angle: angle,
                    });
    
                    if (!shape._objects) {
                        shape.set('fill', fgColor);
                    } else {
                        for (let j = 0; j < shape._objects.length; j++) {
                            shape._objects[j].set({
                            fill: fgColor
                            });
                        }
                    }
                    canvas.add(shape)
                }
            }
            canvas.renderAll();
        })
    }
    if(prevProps.strokeColor !== this.props.strokeColor){
        
        let objects = canvas.getObjects()
        for (let obj of objects){
            if (obj){

                obj.set({
                    stroke: strokeColor,
                });
            }
        }
    }
    if(prevProps.strokeWidth !== this.props.strokeWidth){
        
        let objects = canvas.getObjects()
        for (let obj of objects){
            if (obj){
                if (Number(strokeWidth) === 0){
                    obj.set({
                        stroke: false,
                    }); 
                } else {
                    obj.set({
                        stroke: strokeColor,
                        strokeWidth: strokeWidth,
                        strokeUniform: true
                    });
                }
            }
        }
    }
    canvas.renderAll();
  }

  render() {
    return (
            <canvas id = "TileCanvas" width={this.props.width} height={this.props.height} />
    )
  }
}

export default TileCanvas
