import React from 'react';
import Generator from './Generator.js';
import {RemoveScroll} from 'react-remove-scroll';
import examples from "./data/examples.json";
import {checkImage} from './checkImage.js';


class Detail extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        isTop: true,
        example: 1,
        currentImg: 1,
        theme: "light",
        imgNum: 1
      };
      this.handleScroll = this.handleScroll.bind(this);
      this.handleChangeBG = this.handleChangeBG.bind(this);
      this.testImage = this.testImage.bind(this);
      this.examples = examples[this.props.directory];
      this.imgNum = 1;
    }
  
    componentDidMount() {
      document.addEventListener('mousedown', this.handleClick, false)
      if (this.examples){
        let defaultExample;
        if (this.props.startBG){
          defaultExample = this.props.startBG;
        } else {
          defaultExample = 1
        }
        let newTheme = this.examples[defaultExample-1].theme;
        this.setState({theme: newTheme,
                      example: defaultExample});
      }
      let imgs = document.getElementsByClassName("ThumbnailImgContainer");

      let hNum=0
      for (let i = 6; i > 0; i--){
        this.testImage(`/img/${ this.props.directory }/${i}.jpg`,function(exists){
          if (exists === false){
            if(imgs[i-1-hNum]){
              imgs[i-1-hNum].remove();
              hNum++
            }
          }
          if(exists === true){
          }
          })
        };
    }
    componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleClick, false)
    }

    handleClick = (e) =>{
      if (this.node.contains(e.target)) {
          return;
      }
      this.handleClickOutside();
    }
    handleClickOutside(){
      this.props.handleClose();
    }

    handleScroll(event){
      let scrollTop = document.getElementById('Detail').scrollTop;
      let isTop = scrollTop < 30;
      if (isTop !== this.state.isTop) {
          this.setState({ isTop: isTop })
      }
    };
    handleChangeBG(){
    if (this.examples){
      let i = this.state.example;
      if ((i + 1) <= this.examples.length){i = i + 1;}
      else if ((i + 1) > this.examples.length){i = 1;}
      this.setState({example: i});
        let newTheme = this.examples[i-1].theme;
        this.setState({theme: newTheme});
      }
    };
    handleClickThumbnail(i){
      this.setState({currentImg: i});
    }
    testImage(URL, callBack) {
      // console.log("called")
      var tester=new Image();
      
      tester.onload=()=>{
        // console.log("true")
        callBack(true);
        };
      tester.onerror=()=>{
        // console.log("false")
        callBack(false);
      };
      tester.src=URL;
    }

  
    render(){
      //test how many images are in the folder


      let thumbnails = [];
      for (let i = 1; i<7; i++){
            // console.log("true")
        let thumbnail = <div className="ThumbnailImgContainer"  key={i}>
                    <div style={{border:(i === this.state.currentImg)? "solid 2px black" : "", backgroundImage: `url(/img/${ this.props.directory }/${i}.jpg)`}} 
                  className="ThumbnailImg" onClick={()=>{this.handleClickThumbnail(i)}}></div>
                  </div>
          thumbnails.push(thumbnail); 
      }

      let url;
      if (this.props.url.length > 0){
        url = <div><a className="Detail-Info-Url" href={this.props.url} target="_blank">
                  Learn more
                  <img src= {(this.state.theme === "light")? "/link-black.svg" : "/link-white.svg"} style= {{marginLeft: "8px"}}></img>
                </a></div>
      }

      return(
        <RemoveScroll>
            <div id="Detail" onScroll={this.handleScroll} ref={node => this.node = node}>
                  <div className={(this.state.theme === "light")?(this.state.isTop ? 'BackButtonContainer' : 'BackButtonContainer scrolled'):(this.state.isTop ? 'BackButtonContainer' : 'BackButtonContainer scrolled dark')} >

                      <div className="BackButton" onClick={this.props.handleClose}>
                          <img className={this.state.isTop ? 'BackArrow' : 'BackArrow scrolled'} src= {(this.state.theme === "light")? "/back-arrow.svg" : "/back-arrow-w.svg"} alt="back"></img>
                          <h3 className={(this.state.theme === "light")? "BackText" : "BackText dark"} >Designs</h3>
                      </div>
                      <button className={(this.state.theme === "light")?"ChangeBGButton":"ChangeBGButton dark"} onClick={this.handleChangeBG}>Change Background</button>
                    </div> 

                  <div className="Info" style={{backgroundImage: `url(/img/${ this.props.directory }/e${ this.state.example }.jpg)`}} >
                    <div className="DetailImgCol">
                        <img className="DetailImg" src={`/img/${ this.props.directory }/${this.state.currentImg}.jpg`} ></img>

                        <div className="DetailThumbnails">
                          {thumbnails}
                        </div>
                    </div>               
                    
                    <div className={(this.state.theme === "light")? "Detail-Info-Content" : "Detail-Info-Content dark"}> 
                        <h1 className="Detail-Info-Name">{this.props.name}</h1>
                        <h3 className="Detail-Info">{this.props.designer}, {this.props.year}</h3> 
                        <div className="Detail-Info-Description"><p>{this.props.description}</p></div>
                        {url}

                    </div>              
                </div>

                  <Generator               
                  directory={this.props.directory}
                  />  
            </div>   
        </RemoveScroll>  

      )
    }   
  }
  
  export default Detail;