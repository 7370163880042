import React from 'react';
import './App.css';
import Tiles from './Tiles.js';
import AppHeader from './AppHeader.js';

// initiating firebase for database
import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyAubgKoYDrA16vEEuQTZIwQlAuZN_0K1So",
  authDomain: "form2shape.firebaseapp.com",
  databaseURL: "https://form2shape.firebaseio.com",
  projectId: "form2shape",
  storageBucket: "form2shape.appspot.com",
  messagingSenderId: "488825044040",
  appId: "1:488825044040:web:89176672fac3939c66ac7d",
  measurementId: "G-HHYHV6336G"
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();

firebase.analytics().logEvent('notification_received');


class App extends React.Component {
  constructor(props){
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.state = {mode:1,
                  isTop: true,
                  minimized: false};
  }  
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
  handleMenuClick(num){
    this.setState({mode: num})
  }
  
  handleScroll(event){  
    let isTop = window.scrollY < 100;  
    if (isTop !== this.state.isTop) {
        this.setState({ isTop: isTop })
    }
  };
  footerMinimize(){  
    if (this.state.minimized) {
        this.setState({ minimized: false })
    } else if (!this.state.minimized) {
      this.setState({ minimized: true })
  };
}

  render(){
    let footer= <div className="Footer RegularFooter">
              <div class = "FooterHead">

                  <span className="Footer-text RegularFooterText">Design Pattern Generator</span>
                  <div><a className="FooterMore" href="https://www.s-j-zhang.com">Made by S.J.</a></div>

                  </div>

                </div>

    if (this.state.mode === 1){
      footer = <div className={(this.state.minimized)? "Footer mini" : "Footer"}>
        <div class = "FooterHead">
                      <div id = "FooterHeadExample" onClick = {()=>this.footerMinimize()}>
                      <span className="Footer-text">Example Patterns</span>
                      <div className={(this.state.minimized)? "FooterMiniIcon mini" : "FooterMiniIcon"}></div>
                        
                      </div>
                      
                      <p className="FooterMore" onClick={()=>this.handleMenuClick(4)}>See more</p>

        </div>
                      
                      <Tiles mode={5}/>
                    </div>
    }

    return (
      <div className="App">
        <div className={(this.state.isTop) ? "App-header-container" : "App-header-container scrolled"}>
          <AppHeader isTop= {this.state.isTop}/>
          
          <h5 className={(this.state.isTop) ? "BrowseBy" : "BrowseBy scrolled" }>Browse by</h5>

          <div className="Menu">
            <h4 className={(this.state.mode===1) ? "MenuItem active" : "MenuItem"} onClick={()=>this.handleMenuClick(1)} >Iconic Designs</h4>
            <h4 className={(this.state.mode===2) ? "MenuItem active" : "MenuItem"} onClick={()=>this.handleMenuClick(2)} >Time Machine</h4>
            <h4 className={(this.state.mode===3) ? "MenuItem active" : "MenuItem"} onClick={()=>this.handleMenuClick(3)} >By Designers</h4>
            <div className="MenuDivider"></div>
            <h4 className={(this.state.mode===4) ? "MenuItem active" : "MenuItem"} onClick={()=>this.handleMenuClick(4)} >Generated Patterns</h4>
          </div>
        </div>
        <Tiles mode={this.state.mode}
                minimized={this.state.minimized}/>
        {footer}
      </div>
    );
  }
}

export default App;


