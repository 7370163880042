import React from 'react';
import Timeline from './Timeline.js';
import Popular from './Popular.js';
import Designer from './Designer.js';
import Output from './Output.js';
import OutputFooter from './OutputFooter.js';
import data from "./data/data.json";

// // initiating firebase for database
// import * as firebase from "firebase/app";
// import "firebase/firestore";
// import "firebase/database";

// const firebaseConfig = {
//   apiKey: "AIzaSyAubgKoYDrA16vEEuQTZIwQlAuZN_0K1So",
//   authDomain: "form2shape.firebaseapp.com",
//   databaseURL: "https://form2shape.firebaseio.com",
//   projectId: "form2shape",
//   storageBucket: "form2shape.appspot.com",
//   messagingSenderId: "488825044040",
//   appId: "1:488825044040:web:89176672fac3939c66ac7d",
//   measurementId: "G-HHYHV6336G"
// };
// firebase.analytics();
// firebase.initializeApp(firebaseConfig);
// firebase.analytics().logEvent('notification_received');

// let database = firebase.database();


class Tiles extends React.Component {
    constructor(props){
      super(props);
      this.handleATileOpened = this.handleATileOpened.bind(this);
      this.handleATileClosed = this.handleATileClosed.bind(this);
      this.loadMyAsyncData = this.loadMyAsyncData.bind(this);
      this.updateView = this.updateView.bind(this);
      this.state = {aTileOpened: false,
                    numberShown: 48,
                    externalData: null};
    }
  
    handleATileOpened(){
      this.setState({aTileOpened: true})
    }
    handleATileClosed(){
      this.setState({aTileOpened: false})
    }

    async loadMyAsyncData(num){
      //get data of all pieces
      // let items = [];
      // for(let i=0; i<num; i++){
      //   let item;
      //    await firebase.database().ref(i + '/').once('value').then(function(snapshot) {
      //     item = {
      //       name: (snapshot.val() && snapshot.val().Name) || 'Name missing',
      //       designer: (snapshot.val() && snapshot.val().Designer) || 'Designer missing',
      //       year: (snapshot.val() && snapshot.val().Year) || 'Year missing',
      //       tags: (snapshot.val() && snapshot.val().Tags) || 'Tags missing',
      //       directory: (snapshot.val() && snapshot.val().Directory) || 'Directory missing',
      //       description: (snapshot.val() && snapshot.val().Description) || 'No description',
      //       url: (snapshot.val() && snapshot.val().URL) || 'No url',
      //     }
      //     items.push(item)
      //   });
      // }
      // return items;
    }

    

    updateView(items){
      if (this.props.mode === 1){
        items.sort((a, b) => (a.designer > b.designer) ? 1 : (a.designer === b.designer) ? ((a.year > b.year) ? 1 : -1) : -1 )
        let popular = < Popular items={items}
                    handleATileOpened={this.handleATileOpened}
                    handleATileClosed={this.handleATileClosed}/>
        return popular;
      }else if (this.props.mode === 2){
        let timeline = < Timeline items={items}
                    handleATileOpened={this.handleATileOpened}
                    handleATileClosed={this.handleATileClosed}/>
        return timeline;
      }else if (this.props.mode === 3){
        let designer = < Designer items={items}
                    handleATileOpened={this.handleATileOpened}
                    handleATileClosed={this.handleATileClosed}/>
        return designer;
      }else if (this.props.mode === 4){
        let output = < Output items={items}
                    handleATileOpened={this.handleATileOpened}
                    handleATileClosed={this.handleATileClosed}/>
        return output;
      }else if (this.props.mode === 5){
        let outputFooter = < OutputFooter items={items}
                    handleATileOpened={this.handleATileOpened}
                    handleATileClosed={this.handleATileClosed}/>
        return outputFooter;
      }
    }
  
    componentDidMount() {
      
      // this._asyncRequest = this.loadMyAsyncData(this.state.numberShown).then(
      //   externalData => {
      //     this._asyncRequest = null;
      //     this.setState({externalData});
      //     console.log("from fb"+externalData)
      //   }
      // );

      let items = [];
      for(let i=0; i<data.length; i++){
          if (data[i].Tags.includes('hide') === false && data[i].Name){
            let item = {
              name: data[i].Name,
              designer: data[i].Designer,
              year: data[i].Year,
              tags: data[i].Tags,
              directory: data[i].Directory,
              description: data[i].Description,
              url: data[i].URL,
              startBG: data[i].StartBG
            }
            items.push(item)
          } 
          // else {
          //   console.log(data[i].Designer)
          // }
      }
      this.setState({externalData: items});
    }
  
    componentWillUnmount() {
      if (this._asyncRequest) {
        this._asyncRequest.cancel();
      }
    }
  
    
    render(){

      let loadingTiles = [];
      for(let i = 0; i < 12; i++){      
        loadingTiles.push(
                          <div className = "TileWrapper" key={i}>
                            <div className = "TileWrapper2">
                              <div className="LoadingTile"></div>
                            </div>
                          </div>
        )
      }
      
  
      if (this.state.externalData === null) {
        // Render loading state ...
        return(
          <div className="Body">
            
          <h4 className="App-header-subtitle"> Loading...</h4>
          <div className="Tiles">  
          <div className = "Popular" >
          {loadingTiles}
          </div>
            </div>
          </div>
        )
      } else {
        let viewContent = this.updateView(this.state.externalData);

        if(this.props.mode === 5){
        // Render real Footer UI ...
          return(
            <div>  
              {viewContent}
              </div>
          )
        } else if (this.props.mode === 1) {
          // Render real UI ...
          return(
            <div className={ this.props.minimized ? "Body mini" : "Body"}>
            <div className="Tiles">  
              {viewContent}
              </div>
            </div>
          )
        } else {
          // Render real UI ...
          return(
            <div className="Body mini">
            <div className="Tiles">  
              {viewContent}
              </div>
            </div>
          )
        }
      }
    }
  } 

  export default Tiles;
