import React from 'react';
import Tile from './Tile.js';
import {parseYear} from './parseYear.js';


class Timeline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {opened: false,
                  zoom:2};
    this.handleATileOpened = this.handleATileOpened.bind(this);
    this.handleATileClosed = this.handleATileClosed.bind(this);
    this.handleZoom4 = this.handleZoom4.bind(this);
    this.handleZoom2 = this.handleZoom2.bind(this);
  }

  handleATileOpened(){
    let opened = this.state.opened;
    if (!opened){
      this.setState({opened: true});
    }
    this.props.handleATileOpened();
  }

  handleATileClosed(){
    let opened = this.state.opened;
    if (opened){
      this.setState({opened: false});
    }
    this.props.handleATileClosed();
  }

  handleZoom4(){
    if (this.state.zoom===2){
      this.setState({zoom: 4});
    }
  }
  handleZoom2(){
    if (this.state.zoom===4){
        this.setState({zoom: 2});
      }
  }

  render(){
    let items = this.props.items
    items.sort(function(a, b){
      if (parseYear(a.year) > parseYear(b.year)) return 1;
      if (parseYear(a.year) < parseYear(b.year)) return -1;
      if (a.designer > b.designer) return 1;
      if (a.designer < b.designer) return -1;
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
  });

    let groups = 5;
    let startYear = 1900
    let endYear = 2000
    let interval =  (endYear-startYear) / groups;
    let timelineGroups = [];
    //sort by groups
    for(let j = 0; j < groups; j++){
        let group = [];
        let groupYear = startYear + j*interval; 
        for(let i in items){      
            if(typeof items[i].year === 'number'){
                if (startYear + j*interval <= items[i].year && items[i].year < startYear+(j+1)*interval){
                    group.push(
                        <Tile 
                        key ={`${j}/${i}`}
                        name={items[i].name}
                        year={items[i].year}
                        designer={items[i].designer}
                        directory={items[i].directory}
                        description={items[i].description}
                        tags={items[i].tags}
                        url={items[i].url}
                        startBG={items[i].startBG}

                        size={this.state.zoom}
                
                        handleATileOpened={this.handleATileOpened}
                        handleATileClosed={this.handleATileClosed}
                        /> 
                    )
                }
            }
        }
        let timelineGroup = <TimelineGroup 
                                            key ={j}
                                            zoom = {this.state.zoom}
                                           group = {group}
                                           groupYear = {groupYear}/>
        timelineGroups.push(timelineGroup)        
    }
    return(
        <div className="Timeline">
            <div className="ZoomIcons" >
                <div className="ZoomIcon Icon2" onClick={this.handleZoom2}></div>
                <div className="ZoomIcon Icon4" onClick={this.handleZoom4}></div>
            </div>
            
            <div className="TimelineRows">  
                {timelineGroups}
            </div>

        </div>
        
    )
  }
}

class TimelineGroup extends React.Component {

    render(){
        let TimelineGroupContentClass = "TimelineGroupContent"
        if (this.props.zoom === 2){
            TimelineGroupContentClass = "TimelineZoom2 TimelineGroupContent"
        }else if (this.props.zoom === 4){
            TimelineGroupContentClass = "TimelineZoom4 TimelineGroupContent"
        }
      return(
          <div className="TimelineGroup">
              <div className="TimelineDivider">

                    <div className="TimelineDividerLine"></div>
                    <h1 className="TimelineDividerYear"> {this.props.groupYear}</h1>
                    <div className="TimelineDividerLine"></div>
              </div>
              <div className={TimelineGroupContentClass}>
              
                {this.props.group}
              </div>
          </div>
          
      )
    }
  }

export default Timeline;
