import React from 'react';
import Tile from './Tile.js';
import examples from "./data/examples.json";


class Output extends React.Component {
  constructor(props) {
    super(props);
    this.state = {opened: false};
    this.handleATileOpened = this.handleATileOpened.bind(this);
    this.handleATileClosed = this.handleATileClosed.bind(this);
  }

  handleATileOpened(){
    let opened = this.state.opened;
    if (!opened){
      this.setState({opened: true});
    }
    this.props.handleATileOpened();
  }

  handleATileClosed(){
    let opened = this.state.opened;
    if (opened){
      this.setState({opened: false});
    }
    this.props.handleATileClosed();
  }

  render(){
    let items = this.props.items
    let tiles = [];
    for(let i in items){
      let numberOfExamples = examples[items[i].directory].length;
      for (let j = 1; j < numberOfExamples; j++){
        tiles.push(
          <Tile 
          key ={`${i} + ${j}`}
          name={items[i].name}
          year={items[i].year}
          designer={items[i].designer}
          directory={items[i].directory}
          description={items[i].description}
          tags={items[i].tags}
          url={items[i].url}
          startBG={items[i].startBG}
          coverExample={j}
  
          size="output"
  
          handleATileOpened={this.handleATileOpened}
          handleATileClosed={this.handleATileClosed}
          /> 
        )
      }
    }
    return(
        <div className="Popular">  
        {/* <h4 className="App-header-subtitle">Showing {this.props.items.length} designs</h4> */}

        {tiles}
        </div>
    )
  }
}


export default Output;
