import React from 'react';
import Tile from './Tile.js';
import {parseYear} from './parseYear.js';
import designerData from "./data/designers.json";

const ReactMarkdown = require('react-markdown')


class Designer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {opened: false,
                  zoom:"designer"};
    this.handleATileOpened = this.handleATileOpened.bind(this);
    this.handleATileClosed = this.handleATileClosed.bind(this);

  }

  handleATileOpened(){
    let opened = this.state.opened;
    if (!opened){
      this.setState({opened: true});
    }
    this.props.handleATileOpened();
  }

  handleATileClosed(){
    let opened = this.state.opened;
    if (opened){
      this.setState({opened: false});
    }
    this.props.handleATileClosed();
  }


  render(){
    let items = this.props.items
    items.sort(function(a, b) {
        if (a.designer > b.designer) return 1;
        if (a.designer < b.designer) return -1;
        if (parseYear(a.year) > parseYear(b.year)) return 1;
        if (parseYear(a.year) < parseYear(b.year)) return -1;
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
    });

    let currentDesigner = items[0].designer;
    let designer =[]
    let designerGroups =[]

    for (let i = 0; i < items.length; i++){
        if(items[i].designer === currentDesigner){
            designer.push(<Tile 
                key ={i}
                name={items[i].name}
                year={items[i].year}
                designer={items[i].designer}
                directory={items[i].directory}
                description={items[i].description}
                tags={items[i].tags}
                url={items[i].url}
                startBG={items[i].startBG}

                size={"designer"}
        
                handleATileOpened={this.handleATileOpened}
                handleATileClosed={this.handleATileClosed}
                /> )
        } else {
            designerGroups.push(<DesignerGroup 
                            key ={i}
                            zoom = {this.state.zoom}
                            group = {designer}
                            designer = {currentDesigner}/>)
            currentDesigner = items[i].designer;
            designer = [];
            designer.push(<Tile 
                key ={i}
                name={items[i].name}
                year={items[i].year}
                designer={items[i].designer}
                directory={items[i].directory}
                description={items[i].description}
                tags={items[i].tags}
                url={items[i].url}
                startBG={items[i].startBG}

                size={"designer"}
        
                handleATileOpened={this.handleATileOpened}
                handleATileClosed={this.handleATileClosed}
                /> )
        };
    }
    //add the last group because no change happens at the end
    designerGroups.push(<DesignerGroup 
        key ={'last'}
        zoom = {this.state.zoom}
        group = {designer}
        designer = {currentDesigner}/>)

      //sort designer groups by number of design they have in the catelog      
      designerGroups.sort(function(a, b){
        if (a.props.group.length < b.props.group.length) return 1;
        if (a.props.group.length > b.props.group.length) return -1;
    });

    return(
        <div className="Designer">
            <div className="DesignerCols">  
                {designerGroups}
            </div>

        </div>
        
    )
  }
}

class DesignerGroup extends React.Component {
    render(){
        let info = '';
        let imgCredit = '';
        let year;
        let img;
        let url;
        for (let designer of designerData){
            if (designer.name === this.props.designer){
                info = designer.info;
                year = designer.year;
                img = designer.img;
                imgCredit = designer.imgCredit;
                url = designer.url;
            }
        }
        // const input = '# This is a header\n\nAnd this is a paragraph'

        let DesignerGroupContentClass = "DesignerGroupContent"

      return(
          <div className="DesignerGroup">
              <div className="DesignerInfo">
                  <div className="DesignerImgContainer">
                    <img className="DesignerImg" src={`/designers/${img}`} alt="designer" />
                  </div>
                  <div className="DesignerImgCredit">
                    <p>Image credit to </p>
                    
                      <ReactMarkdown source={imgCredit} />
                    </div>
                  <div className="DesignerBio">
                  <span className = "DesignerBioName"> {this.props.designer}</span>
                    <span className = "DesignerBioYear"> {year}</span>

                  </div>
                    <div className="DesignerBioContent">
                      <ReactMarkdown source={info} />
                    </div>
                    <div><a className="DesignerBioUrl" href={url} target="_blank">
                          Learn more
                          <img src=  "/link-black.svg" style= {{marginLeft: "8px"}}></img>
                   </a></div>
                    
                    
              </div>
              <div className={DesignerGroupContentClass}>
              
                {this.props.group}
              </div>
          </div>
          
      )
    }
  }

export default Designer;
