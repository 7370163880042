import React from 'react';



class AppHeader extends React.Component {
  // constructor(props){
  //   super(props);
  // }  
  render(){

        return (
            <div className="App-header">
                <div className={(this.props.isTop) ? "AppHeaderLeft" : "AppHeaderLeft scrolled"}>

                  {/* <div className={(this.props.isTop) ? "App-header-title" : "App-header-title scrolled"}></div> */}
                  <img 
                  className={(this.props.isTop) ? "App-header-title" : "App-header-title scrolled"}
                  src="/logo.png" width="100%" height="auto"></img>

                  {/* <h3 id="Tagline">A pattern & shape generator inspired by 20th century modernist designs</h3> */}
              </div>
              <div className={(this.props.isTop) ? "AppHeaderRight" : "AppHeaderRight scrolled"}>
                  <p className="AppHeaderP">Featuring a catalogue of historically significant designs from the 20th century. Some of them were pioneers in using unconventional materials, some of them were applauded for their striking forms. But most importantly, the story behind these designs and the thinking of their designers laid the foundation of what the word "Design" means today. </p>
              </div>
            </div>
            );
      }
}

export default AppHeader;



