
import React from 'react'
import reactCSS from 'reactcss'

class CustomColor extends React.Component {
  state = {
    displayColorPicker: false,
    color: this.props.color
  };

  handleChange = () => {
    // this.setState({ color: color.hex })
    let color = {hex:`${this.state.color}`}    
    this.props.handleChange(color)
  };

  render() {

    const styles = reactCSS({
      'default': {
        color: {
          width: '20px',
          height: '20px',
          borderRadius: '2px',
          background: `${ this.props.color }`,
        },
        
      },
    });

    return (
      <div className="ColorSwatch">
          <div style={ styles.color } onClick={ this.handleChange }/>
      </div>
    )
  }
}

export default CustomColor