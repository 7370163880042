import React from 'react';
// import { ReactComponent } from '*.svg';
import Detail from './Detail.js';
import reactCSS from 'reactcss'


class Tile extends React.Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {opened: false};
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleOpen(){
    let opened = this.state.opened;
    if (!opened){
      this.setState({opened: true});
    }
    this.props.handleATileOpened();
  }

  handleClose(){
    let opened = this.state.opened;
    if (opened){
      this.setState({opened: false});
    }
    this.props.handleATileClosed();
  }

  //return big tile is true, small tile if false
  render(){
    let tile = <Preview
                handleOpen={this.handleOpen}
                name={this.props.name}
                year={this.props.year}
                designer={this.props.designer}
                directory={this.props.directory}
                mode={this.props.size}
                coverExample={this.props.coverExample}
                />;

    let opened = this.state.opened;
    let detail = null;
    if(opened){
      detail = <Detail
      handleClose={this.handleClose}
      name={this.props.name}
      year={this.props.year}
      designer={this.props.designer}
      description={this.props.description}
      directory={this.props.directory}
      startBG={this.props.startBG}
      url={this.props.url} />;
    }
    
    

    //check sizing from either timeline pass in different views or keep popular css classes
    //if it is popular renturn this, if timlien, else
      if (this.props.size === 2 || this.props.size === 4){
      return(
        <div>  
        {tile}
        {detail}
        </div>
      )
    } else if (this.props.size === "popular"){
      return(
        <div className="TileWrapper">  
        {tile}
        {detail}
        </div>
      )
    } else if (this.props.size === "designer"){
      return(
        <div className="DesignerTile">  
        {tile}
        {detail}
        </div>
      )
    } else {
      return(
        <div className="TileWrapper">  
        {tile}
        {detail}
        </div>
      )
    }
  }
}

class Preview extends React.Component {

  render(){
    
    let coverExample = (!this.props.coverExample) ? 1 : this.props.coverExample;

    const styles = reactCSS({
      'default': {
        photo: {
          backgroundImage: `url(/img/${ this.props.directory }/JPEG/1.jpg)`,
        },
        output: {
          backgroundImage: `url(/img/${ this.props.directory }/JPEG/e${ coverExample }.jpg)`,
        }
      },
    });
    if (this.props.mode === "output"){
      return(
        <div className="Preview">
            <div className="TileWrapper2"> 
                <div className="Tile"  onClick={this.props.handleOpen}>
                  <div className="TileImage" style={styles.output} ></div>
                </div>
            </div>
        </div>
      )
    } else if (this.props.mode === "footerOutput"){
      return(
        <div className="Preview">
            <div className="FooterOutputWrapper"> 
                <div className="Tile"  onClick={this.props.handleOpen}>
                  <div className="TileImage" style={styles.output} ></div>
                </div>
            </div>
        </div>
      )
    } else if (this.props.mode === 2){
      return(
        <div className="Preview">
            <div className="TimelineTile2"> 
                <div className="Tile"  onClick={this.props.handleOpen}>
                  <div className="TileImage" style={styles.photo} ></div>
                </div>
                <div className="TileInfo">
                    <div className="TileName">{this.props.name}</div> 
                    <div className="TileDesignerYear">{this.props.designer}, {this.props.year}</div> 
                </div>
            </div>
        </div>
      )
    }else if (this.props.mode === 4){
      return(
        <div className="TileWrapper TimelineTile4">
            <div className="TileWrapper2 Timeline4Wrapper"> 
                <div className="Tile"  onClick={this.props.handleOpen}>
                  <div className="TileImage" style={styles.photo} ></div>
                </div>
                <div className="TileInfo mini">
                    <div className="TileName mini">{this.props.name}</div> 
                    {/* <div className="TileYear">{this.props.year}</div>  */}
                    <div className="TileDesigner mini">{this.props.designer}, {this.props.year}</div>
                </div>
            </div>
            
        </div>
      )
    }else if (this.props.mode === "designer"){
      return(
        <div className="Preview">
                <div className="Tile"  onClick={this.props.handleOpen}>
                  <div className="TileImage" style={styles.photo} ></div>
                </div>
                <div className="TileInfo">
                    <div className="TileName">{this.props.name}</div> 
                    <div className="TileYear">{this.props.year}</div> 
                </div>            
        </div>
      )
    }else {
      return(
        <div className="Preview">
            <div className="TileWrapper2"> 
                <div className="Tile"  onClick={this.props.handleOpen}>
                  <div className="TileImage" style={styles.photo} ></div>
                </div>
                <div className="TileInfo">
                    <div className="TileName">{this.props.name}</div> 
                    <div className="TileYear">{this.props.year}</div> 
                    <div className="TileDesigner">{this.props.designer}</div>
                </div>
            </div>
            
        </div>
        
      )
    }
  } 
}

export default Tile;
