import React from "react";
import ColorPicker from './ColorPicker.js';
import CustomColor from './CustomColor.js';
import reactCSS from 'reactcss';
import Canvas from "./Canvas.js";
import TileCanvas from "./TileCanvas.js";
import examples from "./data/examples.json";

class Generator extends React.Component {

    constructor(props){
        super(props);
        this.examples = examples[this.props.directory]
        let startExampleNum = 0;
        let startExample = this.examples[startExampleNum];

        this.state = {file: '1.svg',
                      singleSize: startExample.singleSize,
                      top: startExample.top,
                      left: startExample.left,
                      tileSize: (startExample.tileSize) ? startExample.tileSize : 40,
                      hSpace: (startExample.hSpace) ? startExample.hSpace : 20,
                      vSpace: (startExample.vSpace) ? startExample.vSpace : 20,
                      fgColor: startExample.fgColor,
                      bgColor: startExample.bgColor,
                      strokeColor: (startExample.strokeColor === "#00FFFFFF") ? "#DDDDDD" : startExample.strokeColor,
                      strokeWidth: (startExample.strokeWidth) ? startExample.strokeWidth : 0,
                      tileStrokeColor: (startExample.tileStrokeColor) ? startExample.tileStrokeColor : "#DDDDDD",
                      tileStrokeWidth: 0,
                      fillMode: startExample.fillMode,
                      angle: (startExample.angle) ? startExample.angle : 0,
                      tileAngle: (startExample.tileAngle) ? startExample.tileAngle : 0,
                      canvasSize: 66.67,
                      canvasWidth: 400,
                      canvasHeight: 557.25,
                      activeExample: startExampleNum,
                      singleReset: 0,
                      export: false};

        this.handleSingleSizeChange = this.handleSingleSizeChange.bind(this)
        this.handleStrokeWidthChange = this.handleStrokeWidthChange.bind(this)
        this.handleTileStrokeWidthChange = this.handleTileStrokeWidthChange.bind(this)
        this.handleTileSizeChange = this.handleTileSizeChange.bind(this)
        this.handleTileAngleChange = this.handleTileAngleChange.bind(this)
        this.handleHSpaceChange = this.handleHSpaceChange.bind(this)
        this.handleVSpaceChange = this.handleVSpaceChange.bind(this)
        this.handleExportButton = this.handleExportButton.bind(this)
        this.switchToSingle = this.switchToSingle.bind(this)
        this.switchToTile = this.switchToTile.bind(this)
      }
    componentDidMount(){
        this.resizeCanvasContainer()
        window.addEventListener("resize", this.resizeCanvasContainer.bind(this));
        document.addEventListener('mousedown', this.handleClick, false);
    }
    componentWillUnmount(){
        document.removeEventListener('mousedown', this.handleClick, false)
        window.removeEventListener("resize", this.resizeCanvasContainer.bind(this));

    }
    componentDidUpdate(prevProps) {
        this.resizeCanvasContainer()
    }
    handleClick = (e) =>{
        if (this.node.contains(e.target)) {
            return;
        }
        this.handleClickOutside();
    }
    handleClickOutside(){
        this.setState({export: false})
    }
    resizeCanvasContainer(){
        let wrapper = document.getElementById('CanvasWrapper');
        let container = document.getElementById('CanvasContainer');
        let outputWidth;
        let outputHeight;
        if (wrapper && container){
            let proportion = container.clientHeight/container.clientWidth*100;

            if (this.state.canvasSize<proportion){
                outputWidth = Number(container.clientWidth - 40);
            } else if (this.state.canvasSize>=proportion){
                outputWidth = Number((container.clientHeight - 40)/(this.state.canvasSize/100));
            }
            outputHeight = outputWidth * this.state.canvasSize/100;
            wrapper.style.width = `${outputWidth}px`;
        }
        if(outputWidth !== this.state.canvasWidth || outputHeight !== this.state.canvasHeight){
            this.setState({canvasWidth: Number(outputWidth),
                canvasHeight: Number(outputHeight)});
        }
    }
    resizeCanvas(size){
        this.setState({canvasSize: size});
    }
    switchToSingle() {
        this.setState({fillMode: 'Single'});
    };
    switchToTile() {
        this.setState({fillMode: 'Tile'});
    };
    handleSingleSizeChange(event) {
        this.setState({singleSize: event.target.value});
    };
    handleStrokeWidthChange(event) {
        this.setState({strokeWidth: event.target.value});
    };
    handleTileStrokeWidthChange(event) {
        this.setState({tileStrokeWidth: event.target.value});
    };
    handleTileSizeChange(event) {
        this.setState({tileSize: event.target.value});
    };
    handleTileAngleChange(event){
        this.setState({tileAngle: event.target.value});
    }
    handleHSpaceChange(event) {
        this.setState({hSpace: event.target.value});
    };
    handleVSpaceChange(event) {
        this.setState({vSpace: event.target.value});
    };
    handleFgColorChange = (color) => {
        this.setState({ fgColor: color.hex });
        };
    handleStrokeColorChange = (color) => {
        this.setState({ strokeColor: color.hex });
        };
    handleTileStrokeColorChange = (color) => {
        this.setState({ tileStrokeColor: color.hex });
        };
    handleBgColorChange = (color) => {
        this.setState({ bgColor: color.hex });
        };
    handleExportButton(){
        this.setState({export:!this.state.export})
        this.downloadImage()
    }
    handleSwitchExample(example, i){
        this.setState({activeExample: i})
        
        if(example.fillMode === "Single"){
            this.setState({
                fgColor: example.fgColor,
                bgColor: example.bgColor,
                fillMode: example.fillMode,
                file: example.file,
                singleSize: example.singleSize,
                strokeWidth: (example.strokeWidth) ? example.strokeWidth : 0,
                strokeColor: (example.strokeColor === "#00FFFFFF") ? "#DDDDDD" : example.strokeColor,
                top: example.top,
                left: example.left,
                angle: example.angle,
                singleReset: this.state.singleReset + 1,
            })
        } else if (example.fillMode === "Tile"){
            this.setState({
                fgColor: example.fgColor,
                bgColor: example.bgColor,
                fillMode: example.fillMode,
                file: example.file,
                tileSize: example.tileSize,
                hSpace: example.hSpace,
                vSpace: example.vSpace,
                tileAngle: (example.tileAngle) ? example.tileAngle : 0,
                tileStrokeWidth: (example.tileStrokeWidth) ? example.tileStrokeWidth : 0,
                tileStrokeColor: (example.tileStrokeColor === "#00FFFFFF") ? "#DDDDDD" : example.tileStrokeColor,
            })
        } 
    }
    downloadImage(){
        let canvas = document.getElementById((this.state.fillMode === "Single") ? "SingleCanvas" : "TileCanvas")
        let downloadButton = document.getElementById('DownloadButton');
        downloadButton.href = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
        downloadButton.download = "form2shape.jpg";
    }

    render(){
        let examples=[];
        if (this.examples){
            for(let [i,example] of this.examples.entries()){
                let exampleTile = <div className={(i === this.state.activeExample)?"Example active" : "Example"} onClick={()=>{this.handleSwitchExample(example, i)}} key={i}>
                                    <img src = {`/img/${this.props.directory}/${example.preview}`} alt="example"/>
                                </div>;
                examples.push(exampleTile)
            }
        }
        
        let editingPanel;

        const styles = reactCSS({
            'default': {
              canvas: {
                paddingBottom: `${this.state.canvasSize}%`,
              },
            },
          });

        if (this.state.fillMode === "Single"){
                editingPanel = <div >
                                    <div className="PanelSec" style={{padding:"10px 20px 30px 20px"}}>
                                        <div className="PanelUnitTitle">
                                            <p>Position</p>
                                            {/* <span>{this.state.singleSize}</span> */}
                                            <span onClick={()=>{this.handleSwitchExample(this.examples[this.state.activeExample],this.state.activeExample)}}
                                            className="Pointer">Reset</span>
                                        </div>
                                        <p className="PanelInstruction">Click on the shape and drag to move. Use the bounding box to resize and rotate. </p>
                                        {/* <input type="range"
                                        min="10" max="2000" 
                                        step="0.01"
                                        value={this.state.singleSize} 
                                        className="slider" 
                                        onChange={this.handleSingleSizeChange}
                                        id="sizeRange"></input> */}
                                    </div>
                                    

                                    {/* <div className="PanelSec">
                                        <div className="PanelUnitTitle">
                                            <p>Stroke Width</p>
                                            <input className="PanelInputNum" type="number" value={this.state.strokeWidth} 
                                            onChange={this.handleStrokeWidthChange} min="0" max="10"></input>
                                        </div>
                                        <input type="range"
                                        min="0" max="10" 
                                        step="1"
                                        value={this.state.strokeWidth} 
                                        className="slider" 
                                        onChange={this.handleStrokeWidthChange}
                                        id="strokeWidthRange"></input>
                                        <div className = "PanelUnitTitle">

                                        <p>Stroke Color</p>
                                        <ColorPicker color={ this.state.strokeColor }
                                                    handleChange={ this.handleStrokeColorChange }
                                                />
                                        </div>
                                    </div> */}
                            </div> 

        } else if (this.state.fillMode === "Tile"){
            editingPanel = <div >
                                
                                <div className="PanelSec">
                                    <div className="PanelUnitTitle">
                                        <p>Size</p>
                                        <input className="PanelInputNum" type="number" id="tileSizeRange" value={this.state.tileSize} 
                                        onChange={this.handleTileSizeChange} min="1" max="1000"></input>
                                    </div>
                                    <input type="range"
                                    min="20" max="200" 
                                    step="20"
                                    value={this.state.tileSize} 
                                    className="slider" 
                                    onChange={this.handleTileSizeChange}
                                    id="tileSizeRange"></input>

                                    <div className="PanelUnitTitle">
                                        <p>Rotate</p>
                                        <input className="PanelInputNum" type="number" value={this.state.tileAngle} 
                                            onChange={this.handleTileAngleChange} min="0" max="720"></input>
                                    </div>
                                    <input type="range"
                                    min="0" max="360" 
                                    step="15"
                                    value={this.state.tileAngle} 
                                    className="slider" 
                                    onChange={this.handleTileAngleChange}
                                    id="tileAngleRange"></input>

                                    <div className="PanelUnitTitle">
                                        <p>Horizontal Spacing</p>
                                        <input className="PanelInputNum" type="number" value={this.state.hSpace} 
                                            onChange={this.handleHSpaceChange} min="0" max="1000"></input>
                                    </div>
                                    <input type="range"
                                    min="0" max="200" 
                                    step="20"
                                    value={this.state.hSpace} 
                                    className="slider" 
                                    onChange={this.handleHSpaceChange}
                                    id="hSpaceRange"></input>

                                    <div className="PanelUnitTitle">
                                        <p>Vertical Spacing</p>
                                        <input className="PanelInputNum" type="number" value={this.state.vSpace} 
                                            onChange={this.handleVSpaceChange} min="0" max="1000"></input>
                                    </div>
                                    <input type="range"
                                    min="0" max="200" 
                                    step="20"
                                    value={this.state.vSpace} 
                                    className="slider" 
                                    onChange={this.handleVSpaceChange}
                                    id="vSpaceRange"></input>
                                </div> 
                                {/* <div className="PanelSec no-border">
                                        <div className = "PanelUnitTitle">
                                            <p>Stroke Color</p>
                                            <ColorPicker color={ this.state.tileStrokeColor }
                                                        handleChange={ this.handleTileStrokeColorChange }
                                            />
                                        </div>
                                        <div className="PanelUnitTitle">
                                            <p>Stroke Width</p>
                                            <input className="PanelInputNum" type="number" value={this.state.tileStrokeWidth} 
                                            onChange={this.handleTileStrokeWidthChange} min="0" max="10"></input>
                                        </div>
                                        <input type="range"
                                        min="0" max="10" 
                                        step="1"
                                        value={this.state.tileStrokeWidth} 
                                        className="slider" 
                                        onChange={this.handleTileStrokeWidthChange}
                                        id="tileStrokeWidthRange"></input>
                                </div> */}
                                    
                            </div>
                                
        }
        return(
            <div className="Generator">
                <div className="Upper">
                    <div className="Examples">
                         <div className="PanelSec">
                                <h4 className="PanelHeader">Presets</h4>
                         </div>
                        {examples}
                    </div>
                    <div className="Editor">
                        <div className="CanvasColumn">
                            <div className="CanvasBorder">
                                <div id="CanvasContainer">
                                    <div id="CanvasWrapper">
                                        <div id="Canvas" style={ styles.canvas }>
                                            <div id = "SingleCanvasContainer" 
                                            className={(this.state.fillMode === "Single") ? "Show" : "Hide"}>
                                                <Canvas directory = {this.props.directory}
                                                            file={this.state.file}
                                                            width={this.state.canvasWidth}
                                                            height={this.state.canvasHeight}
                                                            fgColor={this.state.fgColor}
                                                            bgColor={this.state.bgColor}
                                                            // strokeColor={this.state.strokeColor}
                                                            // strokeWidth={this.state.strokeWidth}
                                                            singleSize={this.state.singleSize}
                                                            top={this.state.top}
                                                            left={this.state.left}
                                                            angle={this.state.angle}
                                                            handleSingleScale={this.handleSingleScale}
                                                            reset= {this.state.singleReset}/>   
                                            </div>

                                            <div id = "TileCanvasContainer" 
                                            className={(this.state.fillMode === "Single") ? "Hide" : "Show"}>
                                                <TileCanvas directory = {this.props.directory}
                                                            file={this.state.file}
                                                            width={this.state.canvasWidth}
                                                            height={this.state.canvasHeight}
                                                            fgColor={this.state.fgColor}
                                                            bgColor={this.state.bgColor}
                                                            tileSize={this.state.tileSize}
                                                            // strokeColor={this.state.tileStrokeColor}
                                                            // strokeWidth={this.state.tileStrokeWidth}
                                                            singleSize={this.state.singleSize}
                                                            hSpace= {this.state.hSpace}
                                                            vSpace= {this.state.vSpace}
                                                            angle={this.state.tileAngle}
                                                            handleTileScale={this.handleTileScale}/>   
                                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="panel">
                            <div className="PanelSec">
                                <h4 className="PanelHeader">Customize</h4>
                            </div>
                            <div className="PanelSec">
                                <div className = "PanelUnitTitle">
                                <p>Foreground Color</p>
                                <ColorPicker
                                            color={ this.state.fgColor }
                                            handleChange={ this.handleFgColorChange }
                                        />
                                </div>
                                
                                <div className="ColorSwatchGroup">
                                    <CustomColor
                                    color= "#D52E23"
                                    handleChange={ this.handleFgColorChange }
                                    />
                                                            <CustomColor
                                    color= "#E39D48"
                                    handleChange={ this.handleFgColorChange }
                                    />
                                                            <CustomColor
                                    color= "#F8C93B"
                                    handleChange={ this.handleFgColorChange }
                                    />
                                                            <CustomColor
                                    color= "#337259"
                                    handleChange={ this.handleFgColorChange }
                                    />
                                                            <CustomColor
                                    color= "#416897"
                                    handleChange={ this.handleFgColorChange }
                                    />
                                                            <CustomColor
                                    color= "#D6CCBA"
                                    handleChange={ this.handleFgColorChange }
                                    />
                                </div>

                            </div>
                                             
                            <div className="PanelSec no-border" >
                                <div className = "PanelUnitTitle">
                                    <p>Background Color</p>
                                    <ColorPicker
                                        color={ this.state.bgColor }
                                        handleChange={ this.handleBgColorChange }
                                    />
                                    </div>

                                
                                <div className="ColorSwatchGroup">
                                <CustomColor
                                color= "#FAF1E2"
                                handleChange={ this.handleBgColorChange }
                                />
                                                        <CustomColor
                                color= "#DEE0DD"
                                handleChange={ this.handleBgColorChange }
                                />
                                                        <CustomColor
                                color= "#D6CCBA"
                                handleChange={ this.handleBgColorChange }
                                />
                                                        <CustomColor
                                color= "#AEAEAE"
                                handleChange={ this.handleBgColorChange }
                                />
                                                        <CustomColor
                                color= "#263E54"
                                handleChange={ this.handleBgColorChange }
                                />
                                                        <CustomColor
                                color= "#272524"
                                handleChange={ this.handleBgColorChange }
                                />
                            </div>
                                
                            </div>
                            <div className="FillModeContainer">
                            
                                <div className={(this.state.fillMode === "Single")? 'selected FillModeButton' : 'FillModeButton'} 
                                onClick={this.switchToSingle}>Single</div>
                                <div className={(this.state.fillMode === "Tile")? 'selected FillModeButton' : 'FillModeButton'} 
                                onClick={this.switchToTile}>Tile</div>
                                </div>
                                    {editingPanel}
                            <div className="PanelSec no-border">
                                    <div className="ExportButtonContainer" ref={node => this.node = node}>
                                        <button id="ExportButton" onClick={this.handleExportButton}>Export</button>
                                        <div className={(this.state.export)? "ExportMenu" : "ExportMenu hidden"}>
                                            <a href="" id="DownloadButton"><div className = "ExportMenuItem">Image</div></a>
                                            <a href={`/img/${this.props.directory}/${this.state.file}`} download="form2shape">
                                            <div className = "ExportMenuItem">Unstyled SVG</div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div> 
                </div>
                    <div className="ResizeButtonContainer">
                                <div onClick={()=>this.resizeCanvas(56.25)} 
                                className={(this.state.canvasSize !== 56.25) ? 'ResizeButton' : 'ResizeButton active'}>
                                    <img src="/icons/16-9.svg" alt=""></img>
                                    <h5>16:9</h5>
                                </div>
                                <div onClick={()=>this.resizeCanvas(66.67)} 
                                className={(this.state.canvasSize !== 66.67) ? 'ResizeButton' : 'ResizeButton active'}>
                                    <img src="/icons/3-2.svg" alt=""></img>
                                    <h5>3:2</h5>
                                </div>
                                <div onClick={()=>this.resizeCanvas(75)} 
                                className={(this.state.canvasSize !== 75) ? 'ResizeButton' : 'ResizeButton active'}>
                                    <img src="/icons/4-3.svg" alt=""></img>
                                    <h5>4:3</h5>
                                </div>
                                <div onClick={()=>this.resizeCanvas(100)} 
                                className={(this.state.canvasSize !== 100) ? 'ResizeButton' : 'ResizeButton active'}>
                                    <img src="/icons/1-1.svg" alt=""></img>
                                    <h5>1:1</h5>
                                </div>
                                <div onClick={()=>this.resizeCanvas(133.33)} 
                                className={(this.state.canvasSize !== 133.33) ? 'ResizeButton' : 'ResizeButton active'}>
                                    <img src="/icons/3-4.svg" alt=""></img>
                                    <h5>3:4</h5>
                                </div>
                                <div onClick={()=>this.resizeCanvas(150)} 
                                className={(this.state.canvasSize !== 150) ? 'ResizeButton' : 'ResizeButton active'}>
                                    <img src="/icons/2-3.svg" alt=""></img>
                                    <h5>2:3</h5>
                                </div>
                                <div onClick={()=>this.resizeCanvas(177.78)} 
                                className={(this.state.canvasSize !== 177.78) ? 'ResizeButton' : 'ResizeButton active'}>
                                    <img src="/icons/9-16.svg" alt=""></img>
                                    <h5>9:16</h5>
                                </div>
                            </div>
            </div>
        )
    }
}
  
  export default Generator;