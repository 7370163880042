import React from 'react';
import Tile from './Tile.js';
import {parseYear} from './parseYear.js';


class Popular extends React.Component {
  constructor(props) {
    super(props);
    this.state = {opened: false};
    this.handleATileOpened = this.handleATileOpened.bind(this);
    this.handleATileClosed = this.handleATileClosed.bind(this);
  }

  handleATileOpened(){
    let opened = this.state.opened;
    if (!opened){
      this.setState({opened: true});
    }
    this.props.handleATileOpened();
  }

  handleATileClosed(){
    let opened = this.state.opened;
    if (opened){
      this.setState({opened: false});
    }
    this.props.handleATileClosed();
  }

  render(){
    let items = this.props.items
    items.sort(function(a, b) {
        if (a.designer > b.designer) return 1;
        if (a.designer < b.designer) return -1;
        if (parseYear(a.year) > parseYear(b.year)) return 1;
        if (parseYear(a.year) < parseYear(b.year)) return -1;
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
    });

    let tiles = [];
    for(let i in items){      
      tiles.push(
        <Tile 
        key={i}
        name={items[i].name}
        year={items[i].year}
        designer={items[i].designer}
        directory={items[i].directory}
        description={items[i].description}
        tags={items[i].tags}
        url={items[i].url}
        startBG={items[i].startBG}

        size="popular"

        handleATileOpened={this.handleATileOpened}
        handleATileClosed={this.handleATileClosed}
        /> 
      )
    }
    return(
        <div className="Popular">  
        {/* <h4 className="App-header-subtitle">Showing {this.props.items.length} designs</h4> */}

        {tiles}
        </div>
    )
  }
}


export default Popular;
