import React from 'react'
import { fabric } from 'fabric';

class Canvas extends React.Component {
    constructor(props){
        super(props);
        this.state = {canvas: null,
                    fgColor: this.props.fgColor,
                    bgColor: this.props.bgColor,
                    created: false
                    }
        // this.onObjectScaled = this.onObjectScaled.bind(this)

      }

  componentDidMount() {
      //extract canvas element from html and make it a fabric canvas
    let canvas = new fabric.Canvas("SingleCanvas", {
        backgroundColor: this.props.bgColor,
        selectionColor: 'rgba(0,0,0,0.01)',
        selectionLineWidth: 1,
        selectionBorderColor: 'rgba(0,0,0,0.3)',
    })
    // canvas.on('object:scaled', this.onObjectScaled);

    document.getElementById("SingleCanvas").fabric = canvas;
    let width = this.props.width
    let height = this.props.height
    canvas.setWidth(width);
    canvas.setHeight(height);
    
    let top = this.props.top;
    let left = this.props.left;
    let fgColor = this.props.fgColor;
    let singleSize = this.props.singleSize;
    let strokeColor = this.props.strokeColor;
    let strokeWidth = this.props.strokeWidth;
    let angle = this.props.angle;

    fabric.Object.prototype.noScaleCache = false;


    fabric.loadSVGFromURL(`/img/${this.props.directory}/${this.props.file}`, function(objects, options) {
        let shape = fabric.util.groupSVGElements(objects, options);
        
        if (shape){
            shape.set({
                left: left,
                top: top,
                borderColor: 'rgba(0,0,0,0.3)',
                cornerColor: 'black',
                transparentCorners: false,
                cornerSize: 8,
            });
            if (Number(strokeWidth) === 0 || !strokeWidth){
                shape.set({
                    stroke: false,
                }); 
            } else {
                shape.set({
                    stroke: strokeColor,
                    strokeWidth: strokeWidth,
                    strokeUniform: true
                });
            }
            shape.scaleToWidth(singleSize);
            shape.set({
                angle: angle,                
            });

            //set shape color
            if (!shape._objects) {
                shape.set('fill', fgColor);
            } else {
                for (var i = 0; i < shape._objects.length; i++) {
                    shape._objects[i].set({
                    fill: fgColor
                    });
                }
            }
            canvas.add(shape)
            canvas.renderAll();
            };
        })
         
  }

  componentDidUpdate(prevProps) {    
        let canvas = document.getElementById("SingleCanvas").fabric;
        let singleSize = this.props.singleSize;
        let fgColor = this.props.fgColor;
        let top = this.props.top;
        let left = this.props.left;
        let strokeColor = this.props.strokeColor;
        let strokeWidth = this.props.strokeWidth;
        let angle = this.props.angle;

        //reset canvas width if canvas width updated from Generator
        if (prevProps.width !== this.props.width || prevProps.height !== this.props.height){
            let width = this.props.width
            let height = this.props.height
            canvas.setWidth(width);
            canvas.setHeight(height);
        }

        if (prevProps.file !== this.props.file || prevProps.reset !== this.props.reset){
            canvas.clear();
            fabric.loadSVGFromURL(`/img/${this.props.directory}/${this.props.file}`, function(objects, options) {
                let shape = fabric.util.groupSVGElements(objects, options);
                if (shape){
                    shape.set({
                        left: left,
                        top: top,
                        borderColor: 'rgba(0,0,0,0.3)',
                        cornerColor: 'black',
                        transparentCorners: false,
                        cornerSize: 8,
                    });
                    shape.scaleToWidth(singleSize);
                    shape.set({
                        angle: angle,   
                    });
                    //set color
                    if (!shape._objects) {
                        shape.set('fill', fgColor);
                    } else {
                        for (var i = 0; i < shape._objects.length; i++) {
                            shape._objects[i].set({
                            fill: fgColor
                            });
                        }
                    }
                    canvas.add(shape)
                    canvas.renderAll();
                    };
                })
        }

        if (prevProps.bgColor !== this.props.bgColor){
            canvas.backgroundColor = this.props.bgColor;
        }
        let shape = canvas.item(0);
        if (shape){
            let scaledWidth = shape.getScaledWidth()

            if (prevProps.reset !== this.props.reset){
                shape.set({
                    left: left,
                    top: top,
                    angle: angle,   
                });
                shape.scaleToWidth(singleSize);
            }
 
            if (prevProps.strokeWidth !== this.props.strokeWidth){
                if (Number(strokeWidth) === 0  || !strokeWidth){
                        if (!shape._objects) {
                            shape.set({
                                stroke: false,
                            });
                        } else {
                            for (let i = 0; i < shape._objects.length; i++) {
                                shape._objects[i].set({
                                    stroke: false,
                                });
                            }
                        }
                } else if (strokeWidth && strokeColor){
                    if (!shape._objects) {
                        shape.set({
                            stroke: strokeColor,
                            strokeWidth: strokeWidth,
                            strokeUniform: true
                        });
                    } else {
                        for (let i = 0; i < shape._objects.length; i++) {
                            shape._objects[i].set({
                                stroke: strokeColor,
                                strokeWidth: strokeWidth,
                                strokeUniform: true
                            });
                        }
                    }
                }  

            }
            if (prevProps.top !== this.props.top || prevProps.left !== this.props.left){
                if (shape){
                    shape.set({
                        left: left,
                        top: top,
                    });
                }
            }
            if (prevProps.singleSize !== this.props.singleSize){
                if (shape){
                    shape.scaleToWidth(singleSize);
                }
            }
            if (prevProps.fgColor !== this.props.fgColor){
                if (shape){
                    if (!shape._objects) {
                        shape.set('fill', this.props.fgColor);
                    } else {
                        for (let i = 0; i < shape._objects.length; i++) {
                            shape._objects[i].set({
                            fill: this.props.fgColor
                            });
                        }
                    }  
                }
            }
            if (prevProps.strokeColor !== this.props.strokeColor){
                if (Number(strokeWidth) !== 0 && strokeWidth){
                    if (shape){
                        if (!shape._objects) {
                            shape.set({
                                stroke: strokeColor,
                            });
                        } else {
                            for (let i = 0; i < shape._objects.length; i++) {
                                shape._objects[i].set({
                                    stroke: strokeColor,
                                });
                            }
                        }  
                    }
                }
            }
            
            if (prevProps.angle !== this.props.angle){
                if (shape){
                    shape.set({
                        angle: angle,
                    });
                }
            }

            // if (angle === 0){
            //     shape.scaleToWidth(scaledWidth);
            // } 
            
        }
        canvas.renderAll();   
  }
  onObjectScaled(e){
    var scaledObject = e.target;
    let width = scaledObject.getScaledWidth();
    // let width = scaledObject.width;
    this.props.handleSingleScale(width-1);
  }

  render() {
    return (
            <canvas id = "SingleCanvas" width={this.props.width} height={this.props.height} />
    )
  }
}

export default Canvas
